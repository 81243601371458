<template>
  <AdSyncList channel="facebook"></AdSyncList>
</template>

<script>
import AdSyncList from './segmentRemarketing.vue';

export default {
  name: 'FacebookRemarketingList',
  data() {
    return {};
  },
  components: {
    AdSyncList
  },
  methods: {},
  mounted() {}
};
</script>
